import { useRouter } from 'next/router'
// MUI
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import GavelIcon from '@mui/icons-material/Gavel'
// Helpers and components
import App from '../components/App/App'
import Welcome from '../components/Common/Welcome'
import { navigationMenu } from '../src/dictionaries/dictionariesjsx'
import { buildQueryString, clientWindow, getSetUrlParams, hasAccess } from '../src/helpers/helper' // eslint-disable-line no-unused-vars
import { getUserFromToken } from '../src/helpers/serverSide/userAuthHelper'
import styles from '../src/styles/index.module.css'

const thisFile = 'pages/index ' // eslint-disable-line no-unused-vars

const location = clientWindow?.location
const Index = ({ user }) => {
  const router = useRouter()

  const HeaderBoardComponent = (
    <Typography
      className='qa-logged-in-welcome'
      variant='h5'
      style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
    >
      Welcome, {user?.name}
    </Typography>
  )

  if (user) {
    const { urlParams } = getSetUrlParams()
    const newQueryString = buildQueryString(urlParams)
    // Shared grid props
    const gridProps = {
      direction: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      style: { margin: '1em', padding: '1em' }
    }
    // Shared button props
    const buttonProps = ({ page, icon }) => {
      return {
        className: styles.button,
        size: 'large',
        color: 'primary',
        variant: 'contained',
        'aria-label': 'opportunitiesGrid', // Directly passed so must be straight css and NOT ariaLabel
        // eslint-disable-next-line no-return-assign
        onClick: () => (location.href = '/' + page + newQueryString),
        startIcon: icon
      }
    }
    // All the unique information needed for the render
    const opportunities = navigationMenu.find((menuItem) => menuItem.page === 'opportunities')
    const intakeDashboard = navigationMenu.find((menuItem) => menuItem.page === 'intakeDashboard')
    const caseManagerDashboard = navigationMenu.find((menuItem) => menuItem.page === 'caseManagerDashboard')
    const supervisorDashboard = navigationMenu.find((menuItem) => menuItem.page === 'supervisorDashboard')
    const campaigns = navigationMenu.find((menuItem) => menuItem.page === 'campaigns')
    const settings = navigationMenu.find((menuItem) => menuItem.page === 'settings')

    const gridRows = {
      grids: {
        title: 'Grids',
        hasAccessOpps: hasAccess('page:' + opportunities.page, user),
        oppsButton: {
          page: opportunities.route || opportunities.page,
          icon: user?.permissions.includes('read:onlyViewCases') ? <GavelIcon /> : opportunities.icon,
          innerHtml: user?.permissions.includes('read:onlyViewCases') ? 'Cases' : opportunities.label
        },
        buttons: []
      },
      dashboards: {
        title: 'Dashboards',
        hasAccessIntake: hasAccess('page:' + intakeDashboard.page, user),
        intakeDashboardButton: {
          page: intakeDashboard.route || intakeDashboard.page,
          icon: intakeDashboard.icon,
          innerHtml: intakeDashboard.label
        },
        hasAccessCaseManager: hasAccess('page:' + caseManagerDashboard.page, user),
        caseManagerDashboardButton: {
          page: caseManagerDashboard.route || caseManagerDashboard.page,
          icon: caseManagerDashboard.icon,
          innerHtml: caseManagerDashboard.label
        },
        hasAccessSupervisor: hasAccess('page:' + supervisorDashboard.page, user),
        supervisorDashboardButton: {
          page: supervisorDashboard.route || supervisorDashboard.page,
          icon: supervisorDashboard.icon,
          innerHtml: supervisorDashboard.label
        },
        buttons: []
      },
      dashboards2: {
        // This isn't being displayed by default but is left here so it can be enabled to see how a 4 button row looks
        title: 'Dashboards2',
        hasAccessIntake: hasAccess('page:' + intakeDashboard.page, user),
        intakeDashboardButton: {
          page: intakeDashboard.route || intakeDashboard.page,
          icon: intakeDashboard.icon,
          innerHtml: intakeDashboard.label
        },
        hasAccessCaseManager: hasAccess('page:' + caseManagerDashboard.page, user),
        caseManagerDashboardButton: {
          page: caseManagerDashboard.route || caseManagerDashboard.page,
          icon: caseManagerDashboard.icon,
          innerHtml: caseManagerDashboard.label
        },
        hasAccessCaseManager2: hasAccess('page:' + caseManagerDashboard.page, user),
        caseManagerDashboardButton2: {
          page: caseManagerDashboard.route || caseManagerDashboard.page,
          icon: caseManagerDashboard.icon,
          innerHtml: caseManagerDashboard.label + '2'
        },
        hasAccessSupervisor: hasAccess('page:' + supervisorDashboard.page, user),
        supervisorDashboardButton: {
          page: supervisorDashboard.route || supervisorDashboard.page,
          icon: supervisorDashboard.icon,
          innerHtml: supervisorDashboard.label
        },
        buttons: []
      },
      toolsAndSettings: {
        title: 'Tools and Settings',
        hasAccessCampaigns: hasAccess('page:' + campaigns.page, user),
        campaignsButton: {
          page: campaigns.route || campaigns.page,
          icon: campaigns.icon,
          innerHtml: campaigns.label
        },
        hasAccessSettings: hasAccess('page:' + settings.page, user),
        settingsButton: {
          page: settings.route || settings.page,
          icon: settings.icon,
          innerHtml: settings.label
        },
        buttons: []
      }
    }

    /*
        Now build the actual button components.  This sets our access and unique button props inline.
      */
    // grids
    if (gridRows.grids.hasAccessOpps) {
      gridRows.grids.hasAccess = true
      gridRows.grids.buttons.push(
        <Button {...buttonProps(gridRows.grids.oppsButton)}>{gridRows.grids.oppsButton.innerHtml}</Button>
      )
    }
    // dashboards
    if (gridRows.dashboards.hasAccessIntake) {
      gridRows.dashboards.hasAccess = true
      gridRows.dashboards.buttons.push(
        <Button {...buttonProps(gridRows.dashboards.intakeDashboardButton)}>
          {gridRows.dashboards.intakeDashboardButton.innerHtml}
        </Button>
      )
    }
    if (gridRows.dashboards.hasAccessCaseManager) {
      gridRows.dashboards.hasAccess = true
      gridRows.dashboards.buttons.push(
        <Button {...buttonProps(gridRows.dashboards.caseManagerDashboardButton)}>
          {gridRows.dashboards.caseManagerDashboardButton.innerHtml}
        </Button>
      )
    }
    if (gridRows.dashboards.hasAccessSupervisor) {
      gridRows.dashboards.hasAccess = true
      gridRows.dashboards.buttons.push(
        <Button {...buttonProps(gridRows.dashboards.supervisorDashboardButton)}>
          {gridRows.dashboards.supervisorDashboardButton.innerHtml}
        </Button>
      )
    }

    // Tools And Settings
    if (gridRows.toolsAndSettings.hasAccessCampaigns) {
      gridRows.toolsAndSettings.hasAccess = true
      gridRows.toolsAndSettings.buttons.push(
        <Button {...buttonProps(gridRows.toolsAndSettings.campaignsButton)}>
          {gridRows.toolsAndSettings.campaignsButton.innerHtml}
        </Button>
      )
    }
    if (gridRows.toolsAndSettings.hasAccessSettings) {
      gridRows.toolsAndSettings.hasAccess = true
      gridRows.toolsAndSettings.buttons.push(
        <Button {...buttonProps(gridRows.toolsAndSettings.settingsButton)}>
          {gridRows.toolsAndSettings.settingsButton.innerHtml}
        </Button>
      )
    }

    /*
         At render time, this function will assign the correct css class depending on where the button fell in the row.
         Left / Middle / Right
      */
    const getClassnamePlacement = (length, current) => {
      if (length) {
        if (length > 1) {
          // More than one in a row
          if (length > 2) {
            // Going to have a left / middle(s) / right
            if (length > 3) return styles.buttonDivMiddle // All middle for as much space (auto margin) as possible
            if (current === 0) return styles.buttonDivMiddleLeft
            if (current === length - 1) return styles.buttonDivMiddleRight
            return styles.buttonDivMiddle
          } else {
            // Is exactly 2 so
            // Left and Right
            if (current) return styles.buttonDivRight
            return styles.buttonDivLeft
            // OR
            // Both middle
            // return styles.buttonDivMiddle
          }
        } else {
          return styles.buttonDivMiddle // Only one in the row
        }
      }
    }

    return (
      <App user={user} headerBoard={HeaderBoardComponent} snackProvider router={router}>
        {user?.permissions && user.permissions.includes('read:dashboard') && (
          <div page={thisFile.slice(thisFile.lastIndexOf('/') + 1).trim()}>
            <div style={{ textAlign: 'center' }}>
              <Typography variant='h4' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                CaseOpp Quick Links
              </Typography>
            </div>
            <Grid container {...gridProps}>
              {Object.entries(gridRows)
                .filter(([rowKey, rowValue]) => rowValue.hasAccess)
                .map(([rowKey, rowValue]) => {
                  return (
                    <Grid key={rowKey} container {...gridProps}>
                      <Grid item xs={12}>
                        <Typography
                          variant='h4'
                          style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', paddingBottom: '1em' }}
                        >
                          {rowValue.title}
                        </Typography>
                      </Grid>
                      {rowValue.buttons.map((button, key) => (
                        <Grid key={key} item xs>
                          <div className={getClassnamePlacement(rowValue.buttons.length, key)}>{button}</div>
                        </Grid>
                      ))}
                    </Grid>
                  )
                  // }
                })}
            </Grid>
          </div>
        )}
      </App>
    )
  } else {
    return (
      <App user={user} accessLocation='page:index' router={router}>
        <Welcome loading={user === undefined} from='index' />
      </App>
    )
  }
}

export default Index

export const getServerSideProps = async (context) => {
  const user = await getUserFromToken(context.req, context.res)
  return { props: { user } }
}
